import { useEffect, useState, useCallback, useMemo, Suspense, lazy } from 'react';
import classNames from 'classnames/bind';
import styles from './Posts.module.scss';
import Heading from '~/components/Common/Heading';
import Pagination from '~/components/Common/Pagination';
import Loading from '~/components/Common/Loading';
import * as postServices from '~/services/postServices';
import { Result } from 'antd';

const cx = classNames.bind(styles);

const PostItem = lazy(() => import('~/components/Layouts/Components/PostItem'));

function Posts() {
    const [posts, setPosts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const fetchPosts = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await postServices.getPosts();
            setPosts(response);
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchPosts();
    }, [fetchPosts]);

    const {
        currentPage,
        currentRecords,
        nPages,
        handlePageChange,
    } = usePagination(posts, 6);

    if (isLoading) {
        return <Loading title="Đang tải...." />;
    }

    if (!posts || posts.length === 0) {
        return (
            <Result
                status="404"
                title="404"
                subTitle="Đã xảy ra lỗi không mong muốn, vui lòng thử lại."
                extra={
                    <button
                        className={cx('reload-btn')}
                        onClick={fetchPosts}
                    >
                        Tải lại dữ liệu
                    </button>
                }
            />
        );
    }

    return (
        <div className={cx('wrapper')}>
            <div className={cx('grid')}>
                <div className={cx('posts-content')}>
                    <div className={cx('grid-row')}>
                        <div className={cx('grid-column-12')}>
                            <div className={cx('group-title')}>
                                <Heading className={cx('heading')}>Bài viết nổi bật</Heading>
                                <div className={cx('desc')}>
                                    <p>Tổng hợp các bài viết chia sẽ về kinh nghiệm tự học STEM và các kỹ năng khi làm STEM.</p>
                                </div>
                            </div>
                            <Suspense fallback={<Loading title="Đang tải bài viết..." />}>
                                {currentRecords.map((data, index) => (
                                    <PostItem key={index} data={data} />
                                ))}
                            </Suspense>
                            <Pagination nPages={nPages} currentPage={currentPage} setCurrentPage={handlePageChange} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function usePagination(items, itemsPerPage) {
    const getCurrentPageFromStorage = () => {
        const stored = localStorage.getItem('currentPage');
        const parsed = parseInt(stored, 10);
        return !isNaN(parsed) && parsed > 0 ? parsed : 1;
    };

    const [currentPage, setCurrentPage] = useState(getCurrentPageFromStorage);

    useEffect(() => {
        localStorage.setItem('currentPage', currentPage.toString());
    }, [currentPage]);

    const calculations = useMemo(() => {
        const validItems = Array.isArray(items) ? items : [];
        const indexOfLastRecord = currentPage * itemsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - itemsPerPage;
        const currentRecords = validItems.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(validItems.length / itemsPerPage);

        return {
            currentRecords,
            nPages
        };
    }, [items, currentPage, itemsPerPage]);

    const handlePageChange = useCallback((page) => {
        setCurrentPage(page);
    }, []);

    return {
        currentPage,
        currentRecords: calculations.currentRecords,
        nPages: calculations.nPages,
        handlePageChange,
    };
}

export default Posts;
