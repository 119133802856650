import classNames from 'classnames/bind';
import { useEffect, useMemo, memo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IconDots, IconShare } from '@tabler/icons-react';
import Tippy from '@tippyjs/react/headless';
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from 'react-share';
import { FacebookIcon, TwitterIcon, LinkedinIcon } from 'react-share';
import { message, Result } from 'antd';
import styles from './PostsDetail.module.scss';
import Button from '~/components/Common/Button';
import Heading from '~/components/Common/Heading';
import Reaction from '~/components/Layouts/Components/Reaction';
import FallbackAvatar from '~/components/Common/FallbackAvatar';
import MarkdownParser from '~/components/Layouts/Components/MarkdownParser';
import Loading from '~/components/Common/Loading';
import config from '~/config';
import formatDateToNow from '~/utils/formatDateToNow';
import * as postServices from '~/services/postServices';
import * as userService from '~/services/userServices';

const cx = classNames.bind(styles);

const UserInfo = memo(({ fullName, Component, className, h4 }) => (
    <Component className={cx(className)} h4={h4}>
        {fullName}
    </Component>
));


function PostsDetail() {
    const { postsId } = useParams();
    const [post, setPost] = useState({
        article: null,
        postItem: null,
    });
    const [userInfo, setUserInfo] = useState(null);
    const [showShare, setShowShare] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const shareUrl = window.location.href;

    useEffect(() => {
        const fetchApi = async () => {
            try {
                setIsLoading(true);
                const response = await postServices.getPostsById({ postsId });
                if (!response) {
                    setError(true);
                    return;
                }
                
                const userData = await userService.getUserId({ userId: response.article.userId });
                setUserInfo(userData);
                setPost({
                    article: response.article,
                    postItem: response
                });
            } catch (err) {
                setError(true);
                message.error('Có lỗi xảy ra khi tải bài viết');
            } finally {
                setIsLoading(false);
            }
        }
        
        if (postsId) {
            fetchApi();
        }
    }, [postsId]);


    const fullName = useMemo(() => {
        return userInfo ? `${userInfo.firstName || ''} ${userInfo.lastName || ''}`.trim() : '';
    }, [userInfo]);

    const handleToggleShare = () => setShowShare(prev => !prev);

    const handleCopyLink = () => {
        navigator.clipboard.writeText(shareUrl);
        message.success('Đã sao chép liên kết!');
        setShowShare(false);
    };

    if (isLoading) return <Loading title="Đang tải...." />;
    
    if (error || !post.postItem) {
        return (
            <Result
                status="404"
                title="404"
                subTitle="Xin lỗi, bài viết không tồn tại hoặc đã bị xóa."
                extra={
                    <Button href={config.routes.posts} type="primary">
                        Quay lại 
                    </Button>
                }
            />
        );
    }

    return (
        <div className={cx('wrapper')}>
            <div className={cx('grid', { fullWidth: true })}>
                <div className={cx('grid-row')}>
                    <div className={cx('grid-column-3', { repositoryMarginLeft: true })}>
                        <div className={cx('aside')}>
                            <div style={{ textAlign: 'center' }}>
                                <UserInfo fullName={fullName} Component={Heading} className="userName" h4 />
                            </div>
                            <hr />
                            <Reaction newspaperArticleId={postsId} post={post?.postItem} />
                        </div>
                    </div>
                    <div className={cx('grid-column-9', { repositoryWith: true })}>
                        <article>
                            <Heading className={cx('heading')}>{post?.article?.title}</Heading>
                            <div className={cx('header')}>
                                <div className={cx('user')}>
                                    <span>
                                        <FallbackAvatar
                                            className={cx('avatar')}
                                            pro
                                            linkImage={userInfo?.image || ''}
                                            altImage="avatar"
                                        />
                                    </span>
                                    <div className={cx('info')}>
                                        <span>
                                            <UserInfo fullName={fullName} Component={'span'} className="name" />
                                        </span>
                                        <p className={cx('time')}>{post?.article?.create_at && formatDateToNow(post?.article?.create_at)}</p>
                                    </div>
                                </div>
                                <div className={cx('actions')}>
                                    <Tippy
                                        interactive
                                        visible={showShare}
                                        placement="bottom-end"
                                        render={(attrs) => (
                                            <div className={cx('menu-list')} tabIndex="-1" {...attrs}>
                                                <FacebookShareButton
                                                    url={shareUrl}
                                                    title={post?.article?.title}
                                                    description={post?.article?.description_NA}
                                                    hashtag="#stempost"
                                                    picture={post?.article?.image}
                                                >
                                                    <div className={cx('menu-item')}>
                                                        <div className={cx('menu-icon')}>
                                                            <FacebookIcon size={20} round />
                                                        </div>
                                                        <span className={cx('menu-text')}>Chia sẻ lên Facebook</span>
                                                    </div>
                                                </FacebookShareButton>

                                                <TwitterShareButton
                                                    url={shareUrl}
                                                    title={post?.article?.title}
                                                    hashtags={['stempost']}
                                                    via="STEMPost"
                                                    media={post?.article?.mage}
                                                >
                                                    <div className={cx('menu-item')}>
                                                        <div className={cx('menu-icon')}>
                                                            <TwitterIcon size={20} round />
                                                        </div>
                                                        <span className={cx('menu-text')}>Chia sẻ lên Twitter</span>
                                                    </div>
                                                </TwitterShareButton>

                                                <LinkedinShareButton
                                                    url={shareUrl}
                                                    title={post?.article?.title}
                                                    summary={post?.article?.description_NA}
                                                    source="STEMPost"
                                                    media={post?.article?.image}
                                                >
                                                    <div className={cx('menu-item')}>
                                                        <div className={cx('menu-icon')}>
                                                            <LinkedinIcon size={20} round />
                                                        </div>
                                                        <span className={cx('menu-text')}>Chia sẻ lên LinkedIn</span>
                                                    </div>
                                                </LinkedinShareButton>

                                                <div className={cx('menu-item')} onClick={handleCopyLink}>
                                                    <div className={cx('menu-icon')}>
                                                        <IconShare size={20} />
                                                    </div>
                                                    <span className={cx('menu-text')}>Sao chép liên kết</span>
                                                </div>
                                            </div>
                                        )}
                                        onClickOutside={() => setShowShare(false)}
                                    >
                                        <div onClick={handleToggleShare}>
                                            <Button text>
                                                <IconDots size={20} />
                                            </Button>
                                        </div>
                                    </Tippy>
                                </div>
                            </div>
                            <MarkdownParser content_C={post?.article?.htmlContent} />
                        </article>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default memo(PostsDetail);