import React, { Suspense, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames/bind';
import styles from './Home.module.scss';
import { getTopicTop4 } from '~/app/slices/topicSlice';
import { getPostsAsync } from '~/app/slices/postSlice';
import Loading from '~/components/Common/Loading';
import SlideShow from '~/components/Common/SlideShow';
import Introduce from '~/components/Layouts/Components/Introduce';

const TopicSection = React.lazy(() => import('~/components/Home/TopicSection'));
const PostsSection = React.lazy(() => import('~/components/Home/PostsSection'));

const cx = classNames.bind(styles);

function Home() {
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchData = async () => {
            try {
                await Promise.allSettled([
                    dispatch(getTopicTop4()).unwrap(),
                    dispatch(getPostsAsync()).unwrap()
                ]);
            } catch (error) {
            }
        };

        fetchData();
    }, [dispatch]);

    return (
        <div className={cx('wrapper')}>
            <div className={cx('slideshow')}>
                <section className={cx('slideshow-wrapper')}>
                    <SlideShow />
                </section>
            </div>

            <div className={cx('container', 'grid')}>
                <Suspense fallback={<Loading title="Đang tải chủ đề..." />}>
                    <TopicSection />
                </Suspense>

                <div className={cx('introduce', 'grid-row')}>
                    <Introduce />
                </div>

                <Suspense fallback={<Loading title="Đang tải bài viết..." />}>
                    <PostsSection />
                </Suspense>
            </div>
        </div>
    );
}

export default React.memo(Home);
